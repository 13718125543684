import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';

import ModalSendTemplate from '~/components/Modal/SendTemplate';
import CMSView from '~/components/View';
import ViewModal from '~/components/View/Modal';
import Form from '~/containers/Espace/Form';
import IconEye from '~/icons/Eye';
import IconPaperPlane from '~/icons/PaperPlane';
import params from '~/params/template-email.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const TemplatePageUpdate: FC<PageProps & EspaceProps & UserProps> = function ({
  espace,
  params: { espaceId, id },
  user,
}) {
  const [openSend, setOpenSend] = useState<boolean>(false);

  const handleOpenSendOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenSend(true);
  };

  const handleCloseSendOnClick = (
    event?: SyntheticEvent<HTMLButtonElement>,
  ) => {
    if (event) {
      event.preventDefault();
    }
    setOpenSend(false);
  };

  return (
    <TemplateEspace
      collectionName="templates"
      docId={id}
      espace={espace}
      user={user}
    >
      {openSend && (
        <ViewModal
          closeOnClick={handleCloseSendOnClick}
          maxWidth="500px"
          title="Envoi d'un test du modèle par e-mail"
        >
          <ModalSendTemplate onClose={handleCloseSendOnClick} templateId={id} />
        </ViewModal>
      )}
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix={`/espaces/${espaceId}/actions/templates/`}
          menu={{
            right: [
              {
                icon: IconPaperPlane,
                label: 'Envoyer un e-mail de test',
                onClick: handleOpenSendOnClick,
              },
              {
                icon: IconEye,
                label: 'Voir le modèle au format HTML',
                target: '_blank',
                to: `${process.env.GATSBY_INNEDIT_API_URL}/espaces/${espace.id}/templates/view?id=${id}`,
              },
            ],
          }}
          model={
            new GenericData({
              espaceId,
              params,
              collectionName: 'templates',
            })
          }
          name="template"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(TemplatePageUpdate);
